import React, { useEffect, useState } from "react";
import "./Publications.css";

import { Container } from "react-bootstrap";
import Loader from "react-loader-spinner";

import {
  auth_token,
  auth_token_code,
  authority_msg,
  get_publications_url
} from "../WebController/WebController";

const Publications = () => {
  const [resource, setresource] = useState([]);
  useEffect(() => {
    const formData = new FormData();
    formData.append(auth_token, auth_token_code);

    fetch(get_publications_url, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        setresource(data.data);
      })
      .catch((error) => {
        console.log(authority_msg);
      });
  }, []);

  return (
    <Container className=" pt-3 pb-5 ">
      <div>
        <h4> All Resources and Publications</h4>

        <div className="">
          <div className="  contribute-div pb-5 ">
            {resource.length > 0 ? (
              resource.map((item, index) => (
                <h6 key={index} className=" pt-3 pl-5 title-div">
                  <a className="text-success " href={item.url}>
                    - {item.name}
                  </a>
                </h6>
              ))
            ) : (
              <div className="row justify-content-center align-items-center loader-div">
                <Loader
                  type="Puff"
                  color="green"
                  height={100}
                  width={100}
                  timeout={5000} //5 secs
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};
export default Publications;
