import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";


const firebaseConfig = {
  apiKey: "AIzaSyC-v3u8BDj5VfmF6GPq8tr1wpjxjCPz10I",
  authDomain: "snakehumanconflict.firebaseapp.com",
  projectId: "snakehumanconflict",
  storageBucket: "snakehumanconflict.appspot.com",
  messagingSenderId: "881673278531",
  appId: "1:881673278531:web:0edeb0b2bb05a96a24b0f6",
  measurementId: "G-JLH311DYGH"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth };
export default db;