import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import { Link } from "react-router-dom";
import {
  auth_token,
  auth_token_code,
  authority_msg,
  get_snake_details_url,
  main_web_url
} from "../WebController/WebController";

const SuffleSnakeCard = () => {
  const [snakeSuffle, setSnakeSuffle] = useState([]);

  useEffect(() => {
    const formData = new FormData();
    formData.append("state", "2");
    formData.append(auth_token, auth_token_code);

    fetch(get_snake_details_url, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        let temp = [],
          temp2 = [];
        for (let i = 0; i < 4; i++) {
          const num = Math.floor(Math.random() * data.data.length);
          let numFind = temp2.find((item) => {
            return item === num;
          });
          temp2.push(num);
          if (numFind >= 0) {
            i = i - 1;
          } else {
            temp.push(data.data[num]);
          }
        }
        setSnakeSuffle(temp);
      })
      .catch((error) => {
        console.log(authority_msg);
      });
  }, []);

  return (
    <div className="container-fluid  ">
      <div>
        {snakeSuffle.length > 0 ? (
          <div className="row  justify-content-center ">
            {snakeSuffle.map((item) => (
              <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 pb-2 mb-5 ">
                <div
                  className="main-card h-100 main-div"
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >
                  <img className=" img-fluid p-1" src={main_web_url + item.image} alt={item.english_name} />
                  <div className="p-3 mb-2">
                    <h3 className="text-center text-success title-snake">
                      {item.english_name}
                    </h3>
                    <p className="text-start detail-snake">
                      {item.distributions}
                    </p>
                  </div>
                  <div className="read-div ">
                    <Link
                      to={"/snake_details/" + item.id}
                      className="d-flex justify-content-end align-items-end p-1  div_footer  "
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="row justify-content-center align-items-center  loader-div">
            <Loader
              type="Puff"
              color="green"
              height={100}
              width={100}
              timeout={5000} //5 secs
            />
          </div>
        )}
      </div>
    </div>
  );
};
export default SuffleSnakeCard;
