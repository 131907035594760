/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import "./News.css";

import { Col, Container, Row } from "react-bootstrap";
import Loader from "react-loader-spinner";
import { auth_token, auth_token_code, authority_msg, get_news_url } from "../WebController/WebController";

const News = () => {
  const [newsList, setNewsList] = useState([]);
  const [isLink, setIsLink] = useState(false);
  const [isYT, setIsYT] = useState(false);
  useEffect(() => {
    fetchNewsList();
  }, []);
  const fetchNewsList = async () => {
    const formData = new FormData();
    formData.append(auth_token, auth_token_code);

    await fetch(get_news_url, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        setNewsList(data.data);
        data.data.forEach((item) => {
          if (item.title === "Link") {
            setIsLink(true);
          } else if (item.title === "Youtube") {
            setIsYT(true)
          }
        })


      })
      .catch((error) => {
        console.log(authority_msg);
      });
  }

  return (
    <Container className=" pt-3 pb-5 ">
      <div>
        <div className=" justify-content-center ">
          <div className=" pb-5 ">

            {
              (newsList.length > 0) ? (
                <div>
                  <div>
                    {
                      isLink && (<h4 className="medi-title text-center pt-5 ">News</h4>)
                    }
                    {
                      newsList.length > 0 && (
                        <div className=" justify-content-start  pb-5">
                          {newsList.map((item, index) => (
                            item.title === "Link" && (
                              <div className="d-flex justify-content-start research-item  p-2">
                                <a
                                  className="item-text"
                                  target="_blank"
                                  rel="noreferrer"
                                  href={item.url}
                                >
                                  <p className="news_name">{item.name}</p>
                                </a>
                              </div>
                            )
                          ))}
                        </div>
                      )
                    }
                  </div>
                  <div>
                    {
                      isYT && (<h4 className="medi-title text-center pt-5 ">Videos</h4>)
                    }
                    {
                      newsList.length > 0 && (
                        <div>
                          <Row className="row justify-content-center text-center">
                            {newsList.map((item) => (
                              item.title === "Youtube" && (<Col
                                className=" text-center item-yt m-2"
                                xs={12}
                                sm={5}
                                md={5}
                                lg={5}
                                xl={5}
                                xxl={5}
                              >
                                <iframe
                                  src={item.url}
                                  frameborder="0"
                                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                  title="video"
                                  width={400}
                                  height={250}
                                  className="yt-iframe"
                                  allowFullScreen
                                />
                                <p className="news_name ps-3">{item.name}</p>
                              </Col>)
                            ))}
                          </Row>
                        </div>
                      )
                    }
                  </div>

                </div>
              ) : (
                <div className="row justify-content-center align-items-center loader-div">
                  <Loader
                    type="Puff"
                    color="green"
                    height={100}
                    width={100}
                    timeout={5000} //5 secs
                  />
                </div>
              )
            }

          </div>
        </div>
      </div>
    </Container>
  );
};
export default News;
