import React, { useEffect, useState } from 'react';
import { useParams } from "react-router";
import {
    auth_token,
    authority_msg,
    main_web_url,
    put_shc_apps_data_url
} from "../../WebController/WebController";
import './DashAppsDetails.css';

const DashAppsDetails = () => {
    const { id } = useParams();
    const [appsDetailsData, setAppsDetailsData] = useState({});

    useEffect(() => {
        const auth_token_value = sessionStorage.getItem('auth_token');
        const formData = new FormData();
        formData.append("state", "3");
        formData.append(auth_token, auth_token_value);
        formData.append("id", id);

        fetch(
            put_shc_apps_data_url,
            {
                method: "POST",
                body: formData,
            }
        )
            .then((res) => res.json())
            .then((data) => {
                if(!data.error){
                    setAppsDetailsData(data.data);
                }
                
            })
            .catch((error) => {
                console.log(authority_msg);
            });
    }, []);

    return (
        <div
            className="container-fluid pt-3 pb-5"
            data-aos="fade-up"
            data-aos-duration="3000"
        >
            <div className="row justify-content-center">
                <div className="col-12 col-sm-12 col-md-11 col-lg-7  snake_details_div ">
                    <div className="col-md-6 col-12 col-sm-12 col-lg-6 mx-auto">
                        <img
                            className="img-fluid rounded mx-auto d-block "
                            src={main_web_url + appsDetailsData.image}
                            alt=""
                        />
                    </div>

                    <div className=" pt-2 pb-2">
                        <div className="d-flex details_bg_1 ">
                            <p className=" col-md-3 item_name_title rounded "> Question 1 </p>
                            <p className="col-md-9 item_details rounded  ">
                                {appsDetailsData.question_1}
                            </p>
                        </div>
                        <div className="d-flex details_bg_2 ">
                            <p className="col-md-3 item_name_title rounded"> Question 2 </p>
                            <p className="col-md-9 item_details rounded">
                                {appsDetailsData.question_2}
                            </p>
                        </div>

                        <div className="d-flex details_bg_1 ">
                            <p className="col-md-3 item_name_title rounded"> Place</p>
                            <p className="col-md-9 item_details rounded">
                                {appsDetailsData.place}
                            </p>
                        </div>
                        {
                            parseInt(appsDetailsData.sub_district_id) !== 0 && (<div className="d-flex details_bg_2 ">
                                <p className="col-md-3 item_name_title rounded"> Sub_District(English)  </p>
                                <p className="col-md-9 item_details rounded">

                                    {appsDetailsData.sub_dist_name_eng}
                                </p>
                            </div>)
                        }
                        {
                            parseInt(appsDetailsData.sub_district_id) !== 0 && (<div className="d-flex details_bg_1 ">
                                <p className="col-md-3 item_name_title rounded"> Sub_District(Bangla)  </p>
                                <p className="col-md-9 item_details rounded">

                                    {appsDetailsData.sub_dist_name_ban}
                                </p>
                            </div>)
                        }
                        {
                            parseInt(appsDetailsData.sub_district_id) !== 0 && (<div className="d-flex details_bg_2 ">
                                <p className="col-md-3 item_name_title rounded"> District(English)  </p>
                                <p className="col-md-9 item_details rounded">

                                    {appsDetailsData.dist_name_eng}
                                </p>
                            </div>)
                        }
                        {
                            parseInt(appsDetailsData.sub_district_id) !== 0 && (<div className="d-flex details_bg_1 ">
                                <p className="col-md-3 item_name_title rounded"> District(Bangla)  </p>
                                <p className="col-md-9 item_details rounded">

                                    {appsDetailsData.dist_name_ban}
                                </p>
                            </div>)
                        }




                        <div className="d-flex details_bg_1 ">
                            <p className="col-md-3 item_name_title rounded"> Snake Group Name </p>
                            <p className="col-md-9 item_details rounded">

                                {appsDetailsData.snake_name}
                            </p>
                        </div>

                        <div className="d-flex details_bg_2 ">
                            <p className="col-md-3 item_name_title rounded"> Phone</p>
                            <p className="col-md-9 item_details rounded">

                                {appsDetailsData.phone}
                            </p>
                        </div>



                        <div className="d-flex details_bg_2 ">
                            <p className="col-md-3 item_name_title rounded"> (Latitude, Longitude) </p>
                            <p className="col-md-9 item_details rounded">

                                {"(" + appsDetailsData.latitude + ", " + appsDetailsData.longitude + ")"}
                            </p>
                        </div>
                        <div className="d-flex details_bg_1 ">
                            <p className="col-md-3 item_name_title rounded"> Date </p>
                            <p className="col-md-9 item_details rounded">

                                {appsDetailsData.date}
                            </p>
                        </div>

                        <div className="d-flex details_bg_1 ">
                            <p className="col-md-3 item_name_title rounded"> Current Date-Time </p>
                            <p className="col-md-9 item_details rounded">

                                {appsDetailsData.current_date_time}
                            </p>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default DashAppsDetails;