import React from "react";
import "./About.css";

const About = () => {
  return (
    <div className="div-about container text-left pt-5">
      <div>
        <h2 className="text-success ml-md-4">About SHC</h2>
        <p className="ml-md-3 mr-md-3 mt-3 about_shc">
          <span className="ml-5 pl-5">Collection and analysis</span> of those
          memories may provide vital information for nature research. An app is
          developed for collecting data about snakes and bite incidents that
          occurred within the Bangladesh boundary. This app is free of cost for
          the user. Data collected by this application will be used for research
          purposes. Your data will be secured. Using this application, you will
          be able to capture snakes’ picture instantly and upload here. In case
          of instant upload will record the GPS location, a necessary
          information to authenticate the snake location. The app is easy to
          operate and supported on any android version. <br /> <br />
          <strong>
            After installation there are two options for data submission...
          </strong>
          <br />
          1. You have sighted a snake just now, then open this application, take
          the picture of that snake from a safe distance, and post it with
          answers to our three questions with your mobile phone number. Your
          mobile number will be used only for snake related information
          justification. <br />
          <strong>Note:</strong> Please take pictures from a safe distance. Try
          to have a clear picture but do not try to go near to a live snake.{" "}
          <br />
          2. Another option is from your stored picture. Suppose you have taken
          a picture of a snake. But you could not upload due to internet
          connection. You can upload it when you are available with internet
          connection. Here you have to give obvious information about the
          location of sighting, eg., Name of the Village, Upazilla and district.
          This information is only for authentication for the supplied data.{" "}
          <br />
        </p>
      </div>
      <div className="mt-5 ml-md-3 mr-md-3">
        <h4 className="text-success ml-md-2">OBJECTIVES</h4>
        <p className="mt-3 text-justify">
          Our objectives are to: <br />
          1. To improve the understanding, prevention and treatment of snakebite
          injuries in human beings in Developing Countries. <br />
          2. To achieve significant improvements in outcomes for snakebite
          patients in Developing Countries with measurable key performance
          indicators based on incidence, morbidity, mortality and reductions in
          disability-adjusted life years (DALYs). <br />
        </p>
        <p className="text-justify">
          We believe that the approach we need to take to achieve these
          objectives involves establishing programs to directly impact the
          problems of snakebite envenoming in specific locations around the
          world, and that can do this through activities which incorporate:{" "}
          <br />
          1. Targeted snakebite prevention and first aid promotion activities in
          specific settings and for high risk groups;
          <br />
          2. Assembly and dissemination of available information on appropriate
          medical treatment of snakebite, including development of consensus
          protocols, treatment guidelines and training materials; <br />
          3. Establishment of an investment fund to finance purchases of
          existing antivenoms for distribution to medical facilities in
          Developing Countries, and to finance research and development aimed at
          delivering safe, effective and affordable antivenom products into
          markets that currently lack adequate supply, on a cost recovery basis;
          <br />
          4. Coordination of epidemiological surveillance through the
          establishment of research networks in Developing Countries and by
          deploying standardised recording, reporting and data analysis tools to
          enable reliable estimation of snakebite burden and resource needs;{" "}
          <br />
          5. Provision of expert advocacy on behalf of snakebite victims at
          local, national and international level, to raise awareness of the
          extent of the problem, and to champion the call for adequate public
          health funding of snakebite by national governments of Developing
          Countries, development partners and public health institutions; and{" "}
          <br />
          6. Coordination and promotion of scientific research into snakebite
          related problems by encouraging partnerships between scientists and
          medical professionals in developed and Developing Countries using a
          variety of means and resources. <br /> <br />
        </p>
      </div>
    </div>
  );
};

export default About;
