import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";

import fb_icon from "../../components/Snake-Pic/fb_icon.png";
import github from "../../components/Snake-Pic/github.png";
import gmail_icon from "../../components/Snake-Pic/gmail_icon.png";
import googleplay from "../../components/Snake-Pic/googleplay.png";
import twitter_icon from "../../components/Snake-Pic/twitter_icon.png";
import youtube_icon from "../../components/Snake-Pic/youtube_icon.png";

const Footer = () => {
  return (
    <div className=" mt-auto py-3 bg-success footer-div">
      <div className="col-md-12  d-flex justify-content-center bg-success mt-5 ">
        <div className=" row col-md-10 d-flex justify-content-center div-footer">
          <div className="col-md-3 col-sm-6 col-6  ">
            <p>
              <Link to="/contact" className="text-white">
                About Us
              </Link>
            </p>
            <p>
              <Link to="/news" className="text-white">
                Our News
              </Link>
            </p>
            <p>
              <Link to="/publications" className="text-white">
                Resources and Publications
              </Link>
            </p>

            <p>
              <Link to="/snakes" className="text-white">
                About Snakes
              </Link>
            </p>
          </div>
          <div className="col-md-3 col-sm-6  col-6  ">
            <p className="text-white">SHC Apps get it on </p>
            <a href="https://play.google.com/store/apps/details?id=com.sd.spartan.shc">
              <img
                className="googleplay-img img-fluid"
                src={googleplay}
                alt=" google playstore img"
              />
            </a>
            <p>
              <Link to="/about" className="text-white">
                Other Links
              </Link>
            </p>
          </div>
          <div className="col-md-3 col-sm-6 col-6  ">
            <p className="text-white">Follow us on:</p>

            <div className="d-flex justify-content-center">
              <a href={() => false}>
                <img src={fb_icon} alt="" className="icon-style icon-size" />
              </a>
              <a href={() => false}>
                <img
                  src={youtube_icon}
                  alt=""
                  className="icon-style pr-2 icon-size"
                />
              </a>
              <a href={() => false}>
                <img src={twitter_icon} alt="" className="icon-style pr-2 icon-size" />
              </a>
              <a href={() => false}>
                <img src={gmail_icon} alt="" className="icon-style icon-size" />
              </a>
            </div>
          </div>
          <div className="col-md-3 col-sm-6  col-6  text-white">
            <div className="text-end ">
              <p>
                <b>Copyright to:</b> <br />
                <i className="ml-3">Snake Human Conflict</i>{" "}
              </p>
            </div>
            <p>
              <b>Design and Developed by</b> <br />
              <i className="ml-3">Sarose datta</i> <br />
              <i className="ml-3">CSE, CU</i>
              <p>Follow in:</p>
              <div className="d-flex justify-content-center  ">
                <a href="https://www.facebook.com/sd.saruj.97">
                  <img src={fb_icon} alt="" className="icon-style mr-2 icon-size" />
                </a>
                <a href="https://github.com/Saruj-chy">
                  <img src={github} alt="" className="icon-style icon-size" />
                </a>
              </div>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
