import React, { useEffect, useState } from "react";
import "./CarouselImage.css";

import "react-animated-slider/build/horizontal.css";
import { Carousel } from "react-bootstrap";
import {
  auth_token,
  auth_token_code,
  authority_msg,
  get_snake_details_url,
  main_web_url,
} from "../WebController/WebController";

const CarouselImage = () => {
  const [snakeDetails, setSnakeDetails] = useState([]);

  useEffect(() => {
    const formData = new FormData();
    formData.append("state", "2");
    formData.append(auth_token, auth_token_code);

    fetch(get_snake_details_url, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        setSnakeDetails(data.data);
      })
      .catch((error) => {
        console.log(authority_msg);
      });
  }, []);
  return (
    <Carousel className=" carousel-inner mt-2">
      {snakeDetails.map((item) => (
        <Carousel.Item style={{}}>
          <div className="carouser-div">
            <div className=" text-center ">
              <img
                className="rounded img-fluid "
                src={main_web_url + item.image}
                alt={item.id}
              />
            </div>

            <div className="h-100 w-100 step-div-1">
              <div className="d-flex flex-column justify-content-center align-items-center  w-100 pb-1 step-div-2">
                <p className="text-center sci_div">{item.scientific_name}</p>
              </div>
            </div>
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default CarouselImage;
