/* eslint-disable jsx-a11y/scope */
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import ModalPopupScreen from '../../ModalScreen/ModalPopupScreen';
import { auth_token, authority_msg, main_web_url, put_about_us_url } from '../../WebController/WebController';
import DashInsertAboutUs from '../DashInsertAboutUs/DashInsertAboutUs';
import './DashAboutScreen.css';

const DashAboutScreen = () => {
    const [aboutUs, setAboutUs] = useState([]);
    const [insert, setInsert] = useState(false);
    const [update, setUpdate] = useState(false);
    const [state, setState] = useState("");
    const [id, setId] = useState(0);
    const [imagePath, setImagePath] = useState("");
    const [authToken, setAuthToken] = useState("");


    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    useEffect(() => {
        const auth_token_value = sessionStorage.getItem('auth_token');
        setAuthToken(auth_token_value);
        fetchAboutUsData(auth_token_value);
    }, []);

    const fetchAboutUsData = (auth_token_value) => {
        const formData = new FormData();
        formData.append("state", "2");
        formData.append(auth_token, auth_token_value);

        fetch(put_about_us_url, {
            method: "POST",
            body: formData,
        })
            .then((res) => res.json())
            .then((data) => {
                if (!data.error) {
                    setAboutUs(data.data);
                }

            })
            .catch((error) => {
                console.log(authority_msg);
            });
    }
    const handleLoad = () => {
        setInsert(false);
        setUpdate(false);
        fetchAboutUsData(authToken);
    }

    const handleInsert = () => {
        setState("Insert");
        setInsert(true);
    }


    const handleUpdate = (id) => {
        setState("Update");
        setId(id);
        setUpdate(true);
    }

    const handleDelete = (id, image) => {
        openModal();
        setId(id);
        setImagePath(image);
    }

    const popupCloseSubmit = () => {
        setIsModalOpen(false);
        const formData = new FormData();
        formData.append(auth_token, authToken);
        formData.append("state", "4");
        formData.append("id", id);
        formData.append("image_path", imagePath);

        fetch(put_about_us_url, {
            method: "POST",
            body: formData,
        })
            .then((res) => res.json())
            .then((data) => {
                fetchAboutUsData(authToken);

            })
            .catch((error) => {
                console.log(authority_msg);
            });
    }
    const mainDiv = () => {
        return (
            <div>
                <div className='row align-items-center '>
                    <div className='col-md-10 text-center align-self-center'><p className=' title-p'>About Us</p></div>
                    <div className='col-md-2 text-right'>
                        <button className='buttonStyle' onClick={() => { handleInsert() }}>
                            + Add
                        </button>
                    </div>
                </div>

                <table className="table table-bordered table-striped">
                    <thead className=" font-weight-bold thead-item">
                        <tr className='text-center'>
                            <th className=' th-id'>Id</th>
                            <th className=''>Title</th>
                            <th className=''>Name</th>
                            <th className='wrap image-about'>Details</th>
                            <th className='image-about'>image</th>
                            <th className='th-state'>State</th>

                        </tr>
                    </thead>
                    <tbody>
                        {
                            aboutUs.map((item, index) => (

                                <tr key={index} className='align-items-center' >
                                    <td className='font-weight-bold'>{index + 1}</td>
                                    <td>{item.title}</td>
                                    <td>{item.name}</td>
                                    <td className='wrap'>{item.details}</td>
                                    <td className='image-about' ><a className='text-dark' href={main_web_url + item.image} rel="noreferrer" target='_blank'>{main_web_url + item.image}</a></td>
                                    <td ><div className='row text-center justify-content-center align-items-center icon-div'>
                                        <FontAwesomeIcon onClick={() => handleUpdate(item.id)} className='col-md-12 font-icon' icon={faEdit} /> <br />
                                        <FontAwesomeIcon onClick={() => handleDelete(item.id, item.image)} className='col-md-12  font-icon' icon={faTrash} /></div></td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>

            </div>
        );
    }
    return (


        <div className="container-fluid "><div>
            {
                insert || update ?
                    <DashInsertAboutUs
                        state={state}
                        handleLoad={handleLoad}
                        about_id={id}

                    /> : mainDiv()
            }
            <ModalPopupScreen
                isOpen={isModalOpen}
                onClose={closeModal}
                title="Delete"
                msg="Are you sure, You want to delete this data?"
                popupCloseSubmit={popupCloseSubmit} />

        </div>
        </div>



    );
};

export default DashAboutScreen;