/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import './DashInsertSnakeScreen.css';
import Loader from 'react-loader-spinner';
import ModalPopupScreen from '../../ModalScreen/ModalPopupScreen';
import { auth_token, auth_token_code, authority_msg, get_snake_details_url, put_snake_details_url, snake_details_id_name } from '../../WebController/WebController';

const DashInsertSnakeScreen = ({ state, handleLoad, id }) => {
  const [loading, setLoading] = useState(false);
  const [stateId, setStateId] = useState(0);
  const [image, setImage] = useState("");
  const [nameSci, setNameSci] = useState('');
  const [nameEng, setNameEng] = useState('');
  const [nameLocal, setNameLocal] = useState('');
  const [family, setFamily] = useState('');
  const [venom, setVenom] = useState('');
  const [activePeriod, setActivePeriod] = useState('');
  const [distribution, setDistribution] = useState('');
  const [bangladesh, setBangladesh] = useState('');
  const [habitat, setHabitat] = useState('');
  const [authToken, setAuthToken] = useState("");
  const [imageFilname, setImageFilname] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const [userInfo, setuserInfo] = useState({
    file: [],
    filepreview: null,
  });

  useEffect(() => {
    const auth_token_value = sessionStorage.getItem('auth_token');
    setAuthToken(auth_token_value);
    if (state === "Update") {
      setStateId(4);
      fetchSnakeData();
    } else {
      setStateId(1);
    }

  }, []);
  const fetchSnakeData = async () => {
    const formData = new FormData();
    formData.append("state", "2");
    formData.append(auth_token, auth_token_code);
    formData.append(snake_details_id_name, id);

    await fetch(get_snake_details_url, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (!data.error) {
          setNameSci(data.data.scientific_name);
          setNameEng(data.data.english_name);
          setNameLocal(data.data.local_name);
          setFamily(data.data.family_name);
          setVenom(data.data.venom_type);
          setActivePeriod(data.data.active_period);
          setDistribution(data.data.distributions);
          setBangladesh(data.data.bangladesh);
          setHabitat(data.data.habit_and_habitat);
        }

      })
      .catch((error) => {
        console.log(authority_msg);
      });
  };

  const [invalidImage, setinvalidImage] = useState(null);
  let reader = new FileReader();
  const handleSelectedImage = (event) => {
    const imageFile = event.target.files[0];
    const fileName = event.target.files[0].name;
    setImage(imageFile);
    setImageFilname(fileName);

    if (!imageFile) {
      setinvalidImage('Please select image.');
      return false;
    }

    if (!imageFile.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG|gif)$/)) {
      setinvalidImage('Please select valid image JPG,JPEG,PNG');
      return false;
    }
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {

        //------------- Resize img code ----------------------------------
        var canvas = document.createElement('canvas');
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);

        var MAX_WIDTH = 800;
        var MAX_HEIGHT = 800;
        var width = img.width;
        var height = img.height;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }
        canvas.width = width;
        canvas.height = height;
        ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        ctx.canvas.toBlob((blob) => {
          const file = new File([blob], imageFilname, {
            type: 'image/jpeg',
            lastModified: Date.now()
          });
          setuserInfo({
            ...userInfo,
            file: file,
            filepreview: URL.createObjectURL(imageFile),
          });
        }, 'image/jpeg', 1);
        setinvalidImage(null)
      };
      img.onerror = () => {
        setinvalidImage('Invalid image content.');
        return false;
      };
      //debugger
      img.src = e.target.result;
    };
    reader.readAsDataURL(imageFile);

  };


  const handleSubmit = (event) => {
    event.preventDefault();
    openModal();


  };

  const popupCloseSubmit = () => {
    setIsModalOpen(false);
    setLoading(true);
    const formData = new FormData();
    formData.append(auth_token, authToken);
    formData.append("state", stateId);
    formData.append("scientific_name", nameSci);
    formData.append("english_name", nameEng);
    formData.append("local_name", nameLocal);
    formData.append("family_name", family);
    formData.append("venom_type", venom);
    formData.append("active_period", activePeriod);
    formData.append("distribution", distribution);
    formData.append("bangladesh", bangladesh);
    formData.append("habit_habitat", habitat);
    formData.append("image", image);
    formData.append("id", id);

    fetch(put_snake_details_url, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        handleLoad();
        setLoading(false);

      })
      .catch((error) => {
        console.log(authority_msg);
      });
  };
  return (
    <div className='insert-main-div'>
      {
        !loading ? (<div className='form-div'>
          <div>
            <p className='text-center insert-text'>{state} Snake Details</p>
          </div>
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label className='label-text'>Scientific Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Scientific Name"
                value={nameSci}
                onChange={e => {
                  setNameSci(e.target.value);
                }}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label className='label-text'>English Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter English Name"
                value={nameEng}
                onChange={e => {
                  setNameEng(e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className='label-text'>Local Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Local Name"
                value={nameLocal}
                onChange={e => {
                  setNameLocal(e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className='label-text'>Family Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Family Name"
                value={family}
                onChange={e => {
                  setFamily(e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className='label-text'>Venom Type</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Venom Type"
                value={venom}
                onChange={e => {
                  setVenom(e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className='label-text'>Active Period</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Active Period"
                value={activePeriod}
                onChange={e => {
                  setActivePeriod(e.target.value);
                }}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label className='label-text'>Distribution</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter distribution"
                value={distribution}
                onChange={e => {
                  setDistribution(e.target.value);
                }}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label className='label-text'>Bangladesh</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Bangladesh Details"
                value={bangladesh}
                onChange={e => {
                  setBangladesh(e.target.value);
                }}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label className='label-text'>Habit and Habitat</Form.Label>
              <Form.Control

                type="text"
                placeholder="Enter Habit & Habitat"
                value={habitat}
                onChange={e => {
                  setHabitat(e.target.value);
                }}
              />
            </Form.Group>
            {
              state === "Insert" && (<Form.Group>
                <Form.Label className='label-text'>Image</Form.Label>
                <Form.Control
                  type="file"
                  placeholder="Select Image"
                  onChange={handleSelectedImage}
                  accept="image/*"
                />
              </Form.Group>)
            }


            <Button
              variant="primary"
              type="submit"
              className="w-100 mt-3 bg-success"
            >
              Submit
            </Button>

            <ModalPopupScreen
              isOpen={isModalOpen}
              onClose={closeModal}
              title={state}
              msg={"Are you sure, you want to " + state + " the data?"}
              popupCloseSubmit={popupCloseSubmit} />
          </Form>
        </div>) : (
          <div className="row justify-content-center align-items-center loader-div">
            <Loader
              type="Puff"
              color="green"
              height={100}
              width={100}
            />
          </div>
        )
      }
    </div>

  );
};

export default DashInsertSnakeScreen;