/* eslint-disable no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import Loader from "react-loader-spinner";
import ModalPopupScreen from "../../ModalScreen/ModalPopupScreen";
import {
  auth_token,
  authority_msg,
  put_publications_url,
} from "../../WebController/WebController";

const DashInsertPublication = ({ state, handleLoad, pub_id }) => {
  const [loading, setLoading] = useState(false);
  const [stateId, setStateId] = useState(0);
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");
  const [authToken, setAuthToken] = useState("");


  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  useEffect(() => {
    const auth_token_value = sessionStorage.getItem('auth_token');
    setAuthToken(auth_token_value);
    if (state === "Update") {
      setStateId(3);
      fetchPublicationData(auth_token_value);
    } else {
      setStateId(1);
    }
  }, []);
  const fetchPublicationData = async (auth_token_value) => {
    const formData = new FormData();
    formData.append("state", "5");
    formData.append(auth_token, auth_token_value);
    formData.append("id", pub_id);

    await fetch(put_publications_url, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (!data.error) {
          setName(data.data.name);
          setUrl(data.data.url);
        }

      })
      .catch((error) => {
        console.log(authority_msg);
      });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    openModal();
  };
  const popupCloseSubmit = () => {
    setIsModalOpen(false);
    setLoading(true);
    const formData = new FormData();
    formData.append(auth_token, authToken);
    formData.append("state", stateId);
    formData.append("name", name);
    formData.append("url", url);
    formData.append("id", pub_id);

    fetch(put_publications_url, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {

        handleLoad();
        setLoading(false);
      })
      .catch((error) => {
        console.log(authority_msg);
      });
  };
  return (
    <div className="insert-main-div">
      {!loading ? (
        <div className="form-div">
          <div>
            <p className="text-center insert-text">{state} Publication</p>
          </div>
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label className="label-text">Publication Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Publication Name"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label className="label-text">Publication Url</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Publication Url"
                value={url}
                onChange={(e) => {
                  setUrl(e.target.value);
                }}
              />
            </Form.Group>

            <Button
              variant="primary"
              type="submit"
              className="w-100 mt-3 bg-success"
            >
              Submit
            </Button>

            <ModalPopupScreen
              isOpen={isModalOpen}
              onClose={closeModal}
              title={state}
              msg={"Are you sure, you want to " + state + " the data?"}
              popupCloseSubmit={popupCloseSubmit} />
          </Form>
        </div>
      ) : (
        <div className="row justify-content-center align-items-center loader-div">
          <Loader type="Puff" color="green" height={100} width={100} />
        </div>
      )}
    </div>
  );
};

export default DashInsertPublication;
