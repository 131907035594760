import React from 'react';

const ModalScreen = ({ isOpen, children, onClose }) => {
    if (!isOpen) return null;
    return (
        <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ background: 'white', padding: 20, borderRadius: 5 }}>
                {children}
            </div>
        </div>
    );
};

export default ModalScreen;