/* eslint-disable no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import Loader from 'react-loader-spinner';
import ModalPopupScreen from '../../ModalScreen/ModalPopupScreen';
import ModalScreen from '../../ModalScreen/ModalScreen';
import { auth_token, authority_msg, put_ref_url, put_snake_details_url } from '../../WebController/WebController';

const DashInsertRef = ({ state, handleLoad, ref_id }) => {
    const [snakeDetails, setSnakeDetails] = useState([]);
    const [loading, setLoading] = useState(false);
    const [stateId, setStateId] = useState(0);
    const [name, setName] = useState("");
    const [url, setUrl] = useState("");
    const [snakeDetailsId, setSnakeDetailsId] = useState(0);
    const [selectValue, setSelectValue] = useState("Choose Snake Name");
    const [authToken, setAuthToken] = useState("");


    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const [title, setTitle] = useState("");
    const [msg, setMsg] = useState("");
    const [isModalSubmit, setIsModalSubmit] = useState(false);
    const openModalSubmit = () => setIsModalSubmit(true);
    const closeModalSubmit = () => setIsModalSubmit(false);


    useEffect(() => {
        const auth_token_value = sessionStorage.getItem('auth_token');
        setAuthToken(auth_token_value);
        fetchSnakeList(auth_token_value);
    }, []);

    const fetchSnakeList = async (auth_token_value) => {
        const formData = new FormData();
        formData.append("state", "2");
        formData.append(auth_token, auth_token_value);

        await fetch(put_snake_details_url, {
            method: "POST",
            body: formData,
        })
            .then((res) => res.json())
            .then((data) => {
                if(!data.error){
                    setSnakeDetails(data.data);
                if (state === "Update") {
                    setStateId(3);
                    fetchSnakeDetails(ref_id, data.data, auth_token_value);
                    setTitle("Update Reference");
                    setMsg("Are you sure you want to update this item?");

                } else {
                    setStateId(1);
                    setTitle("Insert Reference");
                    setMsg("Are you sure you want to insert this item?");
                }
                }
                
            })
            .catch((error) => {
                console.log(authority_msg);
            });
    }
    const fetchSnakeDetails = (ref_id, dataList, auth_token_value) => {
        const formData = new FormData();
        formData.append("state", "5");
        formData.append(auth_token, auth_token_value);
        formData.append("ref_id", ref_id);

        fetch(put_ref_url, {
            method: "POST",
            body: formData,
        })
            .then((res) => res.json())
            .then((data) => {
                setName(data.data.name);
                setUrl(data.data.url);
                setSnakeDetailsId(data.data.snake_details_id);
                const selectItem = dataList.filter((item) => {
                    return item.id === data.data.snake_details_id;
                });
                setSelectValue(selectItem[0].english_name + " - " + selectItem[0].local_name);
            })
            .catch((error) => {
                console.log(authority_msg);
            });
    }
    const handleDropdownValue = (event) => {
        setSnakeDetailsId(event.target.value);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if (snakeDetailsId !== 0) {
            // setLoading(true);
            openModalSubmit();
        } else {
            openModal();
        }


    };
    const popupCloseSubmit = () => {
        setIsModalSubmit(false);
        const formData = new FormData();
        formData.append(auth_token, authToken);
        formData.append("state", stateId);
        formData.append("name", name);
        formData.append("url", url);
        formData.append("snake_details_id", snakeDetailsId);
        formData.append("ref_id", ref_id);
        fetch(put_ref_url, {
            method: "POST",
            body: formData,
        })
            .then((res) => res.json())
            .then((data) => {
                setLoading(false);
                handleLoad();
            })
            .catch((error) => {
                console.log(authority_msg);
            });
    }
    return (
        <div className='insert-main-div'>
            {
                !loading ? (<div className='form-div'>
                    <div>
                        <p className='text-center insert-text'>{state} Snake Reference</p>
                    </div>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group>
                            <Form.Label className='label-text'>Reference Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Reference Name"
                                value={name}
                                onChange={e => {
                                    setName(e.target.value);
                                }}
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label className='label-text'>Reference Url</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Reference Url"
                                value={url}
                                onChange={e => {
                                    setUrl(e.target.value);
                                }}
                            />
                        </Form.Group>
                        <div class="input-group mb-3">
                            <select class="custom-select" id="inputGroupSelect01" onChange={handleDropdownValue}>
                                <option value={snakeDetailsId} selected>{selectValue}</option>
                                {
                                    snakeDetails.map((item, index) =>
                                        <option key={index} onClick={() => setSnakeDetailsId(item.id)}
                                            value={item.id}>{item.english_name} - {item.local_name}</option>
                                    )
                                }


                            </select>
                        </div>



                        <Button
                            variant="primary"
                            type="submit"
                            className="w-100 mt-3 bg-success"
                        >
                            Submit
                        </Button>
                        <ModalScreen isOpen={isModalOpen} onClose={closeModal}>
                            <p>Please select the "Choose Snake Name"</p>
                            <button onClick={closeModal}>Close</button>
                        </ModalScreen>
                        <ModalPopupScreen
                            isOpen={isModalSubmit}
                            onClose={closeModalSubmit}
                            title={title}
                            msg={msg}
                            popupCloseSubmit={popupCloseSubmit} />
                    </Form>
                </div>) : (
                    <div className="row justify-content-center align-items-center loader-div">
                        <Loader
                            type="Puff"
                            color="green"
                            height={100}
                            width={100}
                        />
                    </div>
                )
            }
        </div>
    );
};

export default DashInsertRef;