import React, { useEffect, useState } from "react";
import { Animated } from "react-animated-css";
import profile_image from "../Snake-Pic/profile.png";
import logo from "../Snake-Pic/shc_logo.jpg";
import { auth_token, auth_token_code, authority_msg, get_about_us_url, main_web_url } from "../WebController/WebController";
import "./ContactSHCPage.css";

const ContactSHCPage = () => {
  const [aboutUsList, setAboutUsList] = useState([]);
  useEffect(() => {
    fetchAboutUsData();
  }, []);

  const fetchAboutUsData = () => {
    const formData = new FormData();
    formData.append(auth_token, auth_token_code);

    fetch(get_about_us_url, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        setAboutUsList(data.data);

      })
      .catch((error) => {
        console.log(authority_msg);
      });
  }

  return (
    <div className="container-fluid">
      <div className="row justify-content-center ">
        <div className="row col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 mt-3 justify-content-center ">
          <div className="row col-3   justify-content-end ">
            <img src={logo} alt="" className="rounded img-fluid logo_img" />
          </div>
          <div className="row col-9 align-items-center ml-4">
            <h5 className="text-success"> Snake Human Conflict, Bangladesh </h5>
          </div>
        </div>

        <div className="pt-5 pb-5 col-xl-8 col-lg-9 col-md-10 ">
          <div className=" row col-md-12">
            <Animated
              animationIn="fadeInLeft"
              animationOut="fadeOut"
              isVisible={true}
              animationInDelay="100"
              animationInDuration="1000"
              animationOutDelay="120000"
              animateOnMount={true}
            >

              {

                aboutUsList.map((item, index) => (
                  <div key={index} className="row row-col-12 d-flex justify-content-start align-items-center ">
                    <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-4 d-flex justify-content-md-end justify-content-sm-center justify-content-center   pb-3   ">
                      <img
                        src={item.image !== "" ? main_web_url + item.image : profile_image}
                        alt=""
                        className="rounded float-end img-fluid"
                      />
                    </div>
                    <div className="col-md-7 col-lg-7 col-xl-8 pl-3 col-sm-12 col-12  text-center d-flex justify-content-center ">
                      <div className="col-md-12 col-lg-12 col-xl-12  col-sm-7 col-12 text-left ">
                        <h4>{item.title}</h4>
                        <p className="pl-3">
                          <b> {item.name} </b>
                          <br />
                          {item.details.split(";").map((field, indexKey) => (
                            <p className="field-p">{field} </p>
                          ))
                          }
                        </p>
                      </div>
                    </div>
                  </div>))
              }

            </Animated>

          </div>
        </div>

      </div>
    </div>
  );
};

export default ContactSHCPage;
