/* eslint-disable no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import Loader from 'react-loader-spinner';
import ModalPopupScreen from '../../ModalScreen/ModalPopupScreen';
import { auth_token, authority_msg, put_snake_apps_url } from '../../WebController/WebController';

const DashInsertAppsSnake = ({ state, handleLoad, id }) => {
    const [loading, setLoading] = useState(false);
    const [stateId, setStateId] = useState(0);
    const [nameEng, setNameEng] = useState("");
    const [nameBan, setNameBan] = useState("");
    const [image, setImage] = useState("");
    const [authToken, setAuthToken] = useState("");


    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    useEffect(() => {
        const auth_token_value = sessionStorage.getItem('auth_token');
        setAuthToken(auth_token_value);
        if (state === "Update") {
            setStateId(3);
            fetchAppsSnakeData(auth_token_value);
        } else {
            setStateId(1);
        }
    }, []);

    const fetchAppsSnakeData = async (auth_token_value) => {
        const formData = new FormData();
        formData.append("state", "5");
        formData.append(auth_token, auth_token_value);
        formData.append("id", id);

        await fetch(put_snake_apps_url, {
            method: "POST",
            body: formData,
        })
            .then((res) => res.json())
            .then((data) => {
                if (!data.error) {
                    setNameEng(data.data.name_eng);
                    setNameBan(data.data.name_ban);
                    setImage(data.data.image);
                }

            })
            .catch((error) => {
                console.log(authority_msg);
            });
    };
    const handleSelectedImage = (event) => {
        const imageFile = event.target.files[0];
        setImage(imageFile);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        openModal();
    };
    const popupCloseSubmit = () => {
        setIsModalOpen(false);
        setLoading(true);
        const formData = new FormData();
        formData.append(auth_token, authToken);
        formData.append("state", stateId);
        formData.append("name_eng", nameEng);
        formData.append("name_ban", nameBan);
        formData.append("id", id);
        formData.append("image", image);

        fetch(put_snake_apps_url, {
            method: "POST",
            body: formData,
        })
            .then((res) => res.json())
            .then((data) => {
                handleLoad();
                setLoading(false);
            })
            .catch((error) => {
                console.log(authority_msg);
            });
    };
    return (
        <div className="insert-main-div">
            {!loading ? (
                <div className="form-div">
                    <div>
                        <p className="text-center insert-text">{state} Apps Snake Data</p>
                    </div>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group>
                            <Form.Label className="label-text">Name(English)</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Name(English)"
                                value={nameEng}
                                onChange={(e) => {
                                    setNameEng(e.target.value);
                                }}
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label className="label-text">Name(Bangla)</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Name(Bangla)"
                                value={nameBan}
                                onChange={(e) => {
                                    setNameBan(e.target.value);
                                }}
                            />
                        </Form.Group>

                        {
                            state !== "Update" && (<Form.Group>
                                <Form.Label className='label-text'>Image</Form.Label>
                                <Form.Control
                                    type="file"
                                    placeholder="Select Image"
                                    onChange={handleSelectedImage}
                                    accept="image/*"
                                />
                            </Form.Group>)
                        }

                        <Button
                            variant="primary"
                            type="submit"
                            className="w-100 mt-3 bg-success"
                        >
                            Submit
                        </Button>
                        <ModalPopupScreen
                            isOpen={isModalOpen}
                            onClose={closeModal}
                            title={state}
                            msg={"Are you sure, you want to " + state + " the data?"}
                            popupCloseSubmit={popupCloseSubmit} />
                    </Form>
                </div>
            ) : (
                <div className="row justify-content-center align-items-center loader-div">
                    <Loader type="Puff" color="green" height={100} width={100} />
                </div>
            )}
        </div>
    );
};

export default DashInsertAppsSnake;