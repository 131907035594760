/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./SnakeDetails.css";

import { useParams } from "react-router";
import {
  auth_token,
  auth_token_code,
  authority_msg,
  get_snake_details_url,
  main_web_url,
  snake_details_id_name,
} from "../WebController/WebController";

const SnakeDetails = () => {
  const { snake_details_id } = useParams();
  const [snakeDetails, setSnakeDetails] = useState({});
  const [snakeRef, setSnakeRef] = useState([]);

  useEffect(() => {
    const formData = new FormData();
    formData.append("state", "2");
    formData.append(auth_token, auth_token_code);
    formData.append(snake_details_id_name, snake_details_id);

    fetch(
      get_snake_details_url,
      {
        method: "POST",
        body: formData,
      }
    )
      .then((res) => res.json())
      .then((data) => {

        setSnakeDetails(data.data);
        setSnakeRef(data.ref);
      })
      .catch((error) => {
        console.log(authority_msg);
      });
  }, []);

  return (
    <div
      className="container-fluid pt-3 pb-5"
      data-aos="fade-up"
      data-aos-duration="3000"
    >
      <div className="row justify-content-center">
        <div className="col-12 col-sm-12 col-md-11 col-lg-7  snake_details_div ">
          <div className="col-md-6 col-12 col-sm-12 col-lg-6 mx-auto">
            <img
              className="img-fluid rounded mx-auto d-block "
              src={main_web_url + snakeDetails.image}
              alt=""
            />
          </div>

          <div className=" pt-2 pb-2">
            <div className="d-flex details_bg_1 ">
              <p className="item_name rounded "> Scientific Name </p>
              <p className="item_details rounded  ">
                {" "}
                {snakeDetails.scientific_name}{" "}
              </p>
            </div>
            <div className="d-flex details_bg_2 ">
              <p className="item_name rounded"> English Name </p>
              <p className="item_details rounded">
                {" "}
                {snakeDetails.english_name}{" "}
              </p>
            </div>

            <div className="d-flex details_bg_1 ">
              <p className="item_name rounded"> Local Name </p>
              <p className="item_details rounded">
                {" "}
                {snakeDetails.local_name}{" "}
              </p>
            </div>

            <div className="d-flex details_bg_2 ">
              <p className="item_name rounded"> Family Name </p>
              <p className="item_details rounded">
                {" "}
                {snakeDetails.family_name}{" "}
              </p>
            </div>

            <div className="d-flex details_bg_1 ">
              <p className="item_name rounded"> Venom Type </p>
              <p className="item_details rounded">
                {" "}
                {snakeDetails.venom_type}{" "}
              </p>
            </div>

            <div className="d-flex details_bg_2 ">
              <p className="item_name rounded"> Active Period </p>
              <p className="item_details rounded">
                {" "}
                {snakeDetails.active_period}{" "}
              </p>
            </div>

            <div className="d-flex details_bg_1 ">
              <p className="item_name rounded"> Distribution </p>
              <p className="item_details rounded">
                {" "}
                {snakeDetails.distributions}{" "}
              </p>
            </div>

            <div className="d-flex details_bg_2 ">
              <p className="item_name rounded"> Bangladesh </p>
              <p className="item_details rounded">
                {" "}
                {snakeDetails.bangladesh}{" "}
              </p>
            </div>

            <div className="d-flex details_bg_1 ">
              <p className="item_name rounded"> Habit and Habitat </p>
              <p className="item_details rounded">
                {" "}
                {snakeDetails.habit_and_habitat}{" "}
              </p>
            </div>
            <div className="d-flex details_bg_2 ">
              <p className="item_name rounded"> Reference </p>
              <p className="item_details rounded">
                {snakeRef.map((item) => (
                  <div>
                    {item.url === "" ? (
                      <p className="item_details rounded">
                        {" "}
                        {item.id} . {item.name}
                      </p>
                    ) : (
                      <p className="item_details rounded">
                        {" "}
                        {item.id} .{" "}
                        <a href={item.url}> {item.url}</a>
                      </p>
                    )}
                  </div>
                ))}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SnakeDetails;
