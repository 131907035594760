import React, { useEffect, useState } from 'react';
import { auth_token, authority_msg, is_user_valid_url } from '../../WebController/WebController';
import DashAboutScreen from '../DashAboutScreen/DashAboutScreen';
import DashAppsData from '../DashAppsData/DashAppsData';
import DashAppsSnake from '../DashAppsSnake/DashAppsSnake';
import DashAuthScreen from '../DashAuthScreen/DashAuthScreen';
import DashNews from '../DashNews/DashNews';
import DashPublicationScreen from '../DashPublicationScreen/DashPublicationScreen';
import DashRefScreen from '../DashRefScreen/DashRefScreen';
import DashSnakeScreen from '../DashSnakeScreen/DashSnakeScreen';
import DashVolunteer from '../DashVolunteer/DashVolunteer';
import './DashHomeScreen.css';
const DashHomeScreen = () => {
    const [isUserValid, setIsUserValid] = useState(false);
    const [currentContentKey, setCurrentContentKey] = useState('Snakes');
    const contentMap = {
        'Snakes': <DashSnakeScreen />,
        'Snake Reference': <DashRefScreen />,
        'Our News': <DashNews />,
        'Resources & Publications': <DashPublicationScreen />,
        'About Us': <DashAboutScreen />,
        'View Apps Data': <DashAppsData />,
        'Apps Snake Data': <DashAppsSnake />,
        'Volunteer': <DashVolunteer />,
    };

    useEffect(() => {
        const auth_token_value = sessionStorage.getItem('auth_token');
        fetchUserValid(auth_token_value);

    }, []);
    const fetchUserValid = async (auth_token_value) => {
        const formData = new FormData();
        formData.append(auth_token, auth_token_value);

        await fetch(is_user_valid_url, {
            method: "POST",
            body: formData,
        })
            .then((res) => res.json())
            .then((data) => {
                console.log(data)
                setIsUserValid(!data.error);

            })
            .catch((error) => {
                console.log(authority_msg);
            });
    }

    return (
        <div>
            {
                !isUserValid ? (<DashAuthScreen />) :
                    (<div className="col-md-12  d-flex justify-content-center ">
                        <div className='col-md-3 '>
                            <ul>
                                <li className='li-item-main'>Web Fields
                                    <ul>
                                        {Object.keys(contentMap).slice(0, 5).map((key) => (
                                            <li className='li-item text-center' key={key} onClick={() => setCurrentContentKey(key)}>
                                                {key}
                                            </li>
                                        ))}
                                    </ul>
                                </li>

                            </ul>
                            <ul>
                                <li className='li-item-main'>Apps Fields
                                    <ul>
                                        {Object.keys(contentMap).slice(5, 8).map((key) => (
                                            <li className='li-item text-center' key={key} onClick={() => setCurrentContentKey(key)}>
                                                {key}
                                            </li>
                                        ))}
                                    </ul>
                                </li>

                            </ul>
                        </div>
                        <div className='col-md-9'>
                            {contentMap[currentContentKey]}
                        </div>

                    </div>)
            }
        </div>
    );
};

export default DashHomeScreen;