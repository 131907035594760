/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import "./App.css";
import About from "./components/About/About";
import ContactSHCPage from "./components/ContactSHCPage/ContactSHCPage";
import NavbarComponent from "./components/NavbarComponent/NavbarComponent";

import DashAppsDetails from "./components/Dashboard/DashAppsData/DashAppsDetails";
import DashAuthScreen from "./components/Dashboard/DashAuthScreen/DashAuthScreen";
import DashHomeScreen from "./components/Dashboard/DashHome/DashHomeScreen";
import Footer from "./components/Footer/Footer";
import Home from "./components/Home/Home";
import News from "./components/News/News";
import Publications from "./components/Publications/Publications";
import SnakeGroup from "./components/SnakeGroup/SnakeGroup";
import SnakeDetails from "./components/Snake_details/SnakeDetails";

function App() {
  return (
    <div className="main-div">
      <Router>
        <div className="">
          <NavbarComponent />
          <div className="bg-white ">
            <Switch>
              <Route path="/about">
                <About />
              </Route>

              <Route path="/news">
                <News />
              </Route>
              <Route path="/publications">
                <Publications />
              </Route>

              <Route path="/contact">
                <ContactSHCPage />
              </Route>
              <Route path="/snakes">
                <SnakeGroup />
              </Route>
              <Route path="/snake_details/:snake_details_id">
                <SnakeDetails />
              </Route>

              <Route path="/apps_details/:id">
                <DashAppsDetails />
              </Route>

              <Route path="/auth">
                <DashAuthScreen />
              </Route>
              <Route path="/dashboard">
                <DashHomeScreen />
              </Route>
              <Route path="/">
                <Home />
              </Route>
            </Switch>

            <Footer />
          </div>
        </div>
      </Router>
    </div>
  );
}

export default App;
