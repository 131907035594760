import React from "react";
import "./Home.css";
import { Col, Container, Row } from "react-bootstrap";
import CarouselImage from "../CarouselImage/CarouselImage";

import { Link } from "react-router-dom";
import About from "../About/About";
import SuffleSnakeCard from "../SuffleSnakeCard/SuffleSnakeCard";

const Home = () => {
  return (
    <section className="container-fluid">
      <div className="row justify-content-center ">
        <div className="col-md-12 col-12 col-sm-12 col-lg-12 col-xl-10 ">
          <Container fluid className="mt-2">
            <Row className="pb-2 pt-5">
              <Col
                xs={12}
                md={12}
                lg={8}
                sm={12}
                xxl
                xl={8}
                className="text-center justify-content-center align-items-center align-self-center "
              >
                <div className=" about-div">
                  <About />
                </div>
              </Col>
              <Col
                xs={12}
                md={12}
                lg={4}
                sm={12}
                xxl
                xl={4}
                className="text-center justify-content-center align-items-center align-self-center pt-5"
              >
                <div className=" ">
                  <CarouselImage />
                </div>
              </Col>
            </Row>
          </Container>

          <div className="mt-5 ">
            <div className="col-md-12 text-success row justify-content-between  ">
              <h3 className="text-success">About Snakes of Bangladesh</h3>
            </div>
            <SuffleSnakeCard />

            <Link
              className="text-success  row  justify-content-end pb-5"
              to={"/snakes"}
            >
              ...Read More
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Home;
