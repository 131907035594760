/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import Loader from 'react-loader-spinner';
import ModalPopupScreen from '../../ModalScreen/ModalPopupScreen';
import ModalScreen from '../../ModalScreen/ModalScreen';
import { auth_token, authority_msg, put_news_url } from '../../WebController/WebController';

const DashInsertNews = ({ state, handleLoad, id }) => {
    const [loading, setLoading] = useState(false);
    const [stateId, setStateId] = useState(0);
    const [name, setName] = useState("");
    const [url, setUrl] = useState("");
    const [selectValue, setSelectValue] = useState("Link");
    const [authToken, setAuthToken] = useState("");


    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const [title, setTitle] = useState("");
    const [msg, setMsg] = useState("");
    const [isModalSubmit, setIsModalSubmit] = useState(false);
    const openModalSubmit = () => setIsModalSubmit(true);
    const closeModalSubmit = () => setIsModalSubmit(false);


    useEffect(() => {
        const auth_token_value = sessionStorage.getItem('auth_token');
        setAuthToken(auth_token_value);
        if (state === "Update") {
            setStateId(3);
            fetchNewsList(auth_token_value);
            setTitle("Update News");
            setMsg("Are you sure you want to update this item?");

        } else {
            setStateId(1);
            setTitle("Insert News");
            setMsg("Are you sure you want to insert this item?");
        }
    }, []);

    const fetchNewsList = (auth_token_value) => {
        const formData = new FormData();
        formData.append("state", "5");
        formData.append(auth_token, auth_token_value);
        formData.append("id", id);

        fetch(put_news_url, {
            method: "POST",
            body: formData,
        })
            .then((res) => res.json())
            .then((data) => {
                if (!data.error) {
                    setName(data.data.name);
                    setUrl(data.data.url);
                    setSelectValue(data.data.title);
                }

            })
            .catch((error) => {
                console.log(authority_msg);
            });
    }
    const handleDropdownValue = (event) => {
        setSelectValue(event.target.value);
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        if (selectValue !== "") {
            openModalSubmit();
        } else {
            openModal();
        }


    };
    const popupCloseSubmit = () => {
        setIsModalSubmit(false);
        const formData = new FormData();
        formData.append(auth_token, authToken);
        formData.append("state", stateId);
        formData.append("name", name);
        formData.append("url", url);
        formData.append("title", selectValue);
        formData.append("id", id);
        fetch(put_news_url, {
            method: "POST",
            body: formData,
        })
            .then((res) => res.json())
            .then((data) => {
                setLoading(false);
                handleLoad();
            })
            .catch((error) => {
                console.log(authority_msg);
            });
    }
    return (
        <div className='insert-main-div'>
            {
                !loading ? (<div className='form-div'>
                    <div>
                        <p className='text-center insert-text'>{state} Our News</p>
                    </div>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group>
                            <Form.Label className='label-text'>News Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter News Name"
                                value={name}
                                onChange={e => {
                                    setName(e.target.value);
                                }}
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label className='label-text'>News Url</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter News Url"
                                value={url}
                                onChange={e => {
                                    setUrl(e.target.value);
                                }}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label className='label-text'>Choose a option</Form.Label>
                            <select class="custom-select" id="inputGroupSelect01" onChange={handleDropdownValue}>
                                {
                                    selectValue === "Link" ? <option value={"Link"} selected>Link</option> : <option value={"Link"}>Link</option>
                                }
                                {
                                    selectValue === "Youtube" ? <option value={"Youtube"} selected>Youtube</option> : <option value={"Youtube"}>Youtube</option>
                                }



                            </select>
                        </Form.Group>



                        <Button
                            variant="primary"
                            type="submit"
                            className="w-100 mt-3 bg-success"
                        >
                            Submit
                        </Button>
                        <ModalScreen isOpen={isModalOpen} onClose={closeModal}>
                            <p>Please select the "Choose a option"</p>
                            <button onClick={closeModal}>Close</button>
                        </ModalScreen>
                        <ModalPopupScreen
                            isOpen={isModalSubmit}
                            onClose={closeModalSubmit}
                            title={title}
                            msg={msg}
                            popupCloseSubmit={popupCloseSubmit} />
                    </Form>
                </div>) : (
                    <div className="row justify-content-center align-items-center loader-div">
                        <Loader
                            type="Puff"
                            color="green"
                            height={100}
                            width={100}
                        />
                    </div>
                )
            }
        </div>
    );
};

export default DashInsertNews;