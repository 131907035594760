import { faWindowClose } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const ModalPopupScreen = ({ isOpen, onClose, popupCloseSubmit, title, msg }) => {
    if (!isOpen) return null;
    return (
        <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ background: 'white', padding: 20, borderRadius: 5 }}>
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">{title}</h5>
                            {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                            <FontAwesomeIcon onClick={() => { onClose() }} icon={faWindowClose} />
                        </div>
                        <div class="modal-body">
                            <p>{msg}</p>
                        </div>
                        <div class="modal-footer">
                            <button onClick={() => popupCloseSubmit()} type="button" class="btn btn-success">Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default ModalPopupScreen;