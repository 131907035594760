import { faDownload, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import ModalPopupScreen from '../../ModalScreen/ModalPopupScreen';
import { auth_token, authority_msg, put_ref_url } from '../../WebController/WebController';
import DashInsertRef from '../DashInsertRef/DashInsertRef';
import './DashRefScreen.css';


const DashRefScreen = () => {
    const [ref, setRef] = useState([]);
    const [insert, setInsert] = useState(false);
    const [update, setUpdate] = useState(false);
    const [state, setState] = useState("");
    const [id, setId] = useState(0);
    const [authToken, setAuthToken] = useState("");

    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);


    useEffect(() => {
        const auth_token_value = sessionStorage.getItem('auth_token');
        setAuthToken(auth_token_value);
        fetchRefList(auth_token_value);
    }, []);
    const fetchRefList = (auth_token_value) => {
        const formData = new FormData();
        formData.append("state", "2");
        formData.append(auth_token, auth_token_value);

        fetch(put_ref_url, {
            method: "POST",
            body: formData,
        })
            .then((res) => res.json())
            .then((data) => {
                if (!data.error) {
                    setRef(data.data);
                }

            })
            .catch((error) => {
                console.log(authority_msg);
            });
    }

    const handleLoad = () => {
        setInsert(false);
        setUpdate(false);
        fetchRefList(authToken);
    }

    const handleInsert = () => {
        setState("Insert");
        setInsert(true);
    }
    const handleUpdate = (id) => {
        setState("Update");
        setId(id);
        setUpdate(true);
    }

    const handleDelete = (ref_id) => {
        openModal();
        setId(ref_id);

    }
    const popupCloseSubmit = () => {
        setIsModalOpen(false);
        const formData = new FormData();
        formData.append(auth_token, authToken);
        formData.append("state", "4");
        formData.append("ref_id", id);
        fetch(put_ref_url, {
            method: "POST",
            body: formData,
        })
            .then((res) => res.json())
            .then((data) => {
                fetchRefList(authToken);

            })
            .catch((error) => {
                console.log(authority_msg);
            });
    }
    const mainDiv = () => {
        return (<div>
            <div className='row align-items-center '>
                <div className='col-sm-6 col-md-9 text-center align-self-center'><p className=' title-p'>Reference</p></div>
                <div className='col-sm-3 col-md-2 text-right'>
                    <button className='buttonStyle' onClick={() => { handleInsert() }}>
                        + Add
                    </button>

                </div>
                <CSVLink className='col-sm-3 col-md-1 csv-icon  p-3' data={ref} target='_blank' filename={"reference_list.csv"}> <FontAwesomeIcon icon={faDownload} /></CSVLink>;

            </div>

            <table className="table table-bordered table-striped">
                <thead className=" font-weight-bold thead-item">
                    <tr className='text-center'>
                        <th className=' th-id'><p>Id</p></th>
                        <th className=' name-url '><p>Name</p></th>
                        <th className=' news-url-item'><p>Url</p></th>
                        <th className=' th-snake-details-id ' >Snake Details Id</th>
                        <th className=' th-state '><p>State</p></th>

                    </tr>
                </thead>
                <tbody>
                    {
                        ref.map((item, index) => (

                            <tr key={index} className='align-items-center' >
                                <td className='font-weight-bold'>{index + 1}</td>
                                <td>{item.name}</td>
                                <td ><a className='text-dark item-click' href={item.url}>{item.url}</a></td>
                                <td><Link className='text-dark item-click' to={"/snake_details/" + item.snake_details_id}>{item.snake_details_id}</Link></td>
                                <td ><div className='row text-center justify-content-center align-items-center icon-div'>
                                    <FontAwesomeIcon onClick={() => handleUpdate(item.id)} className='col-md-12 font-icon' icon={faEdit} /> <br />
                                    <FontAwesomeIcon onClick={() => handleDelete(item.id)} className='col-md-12  font-icon' icon={faTrash} /></div></td>
                            </tr>
                        ))
                    }
                </tbody>
            </table> </div>);
    }
    return (
        <div className="container-fluid ">

            <div>
                {
                    insert || update ?
                        <DashInsertRef
                            state={state}
                            handleLoad={handleLoad}
                            ref_id={id}
                        /> : mainDiv()
                }

                <ModalPopupScreen
                    isOpen={isModalOpen}
                    onClose={closeModal}
                    title="Delete Reference"
                    msg="Are you sure, You want to delete the data?"
                    popupCloseSubmit={popupCloseSubmit} />


            </div>
        </div>

    );
};

export default DashRefScreen;