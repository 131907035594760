import React from "react";
import { Nav, Navbar } from "react-bootstrap";

import { Link } from "react-router-dom";
import "./NavbarComponent.css";
const NavbarComponent = () => {
  return (
    <div className="">
      <Navbar
        collapseOnSelect
        bg="success"
        variant="dark"
        expand="lg md sm xl"
        className="nav-main "
      >
        <Navbar.Brand>Snake Human Conflict</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto ">
            <Nav.Link href="/" style={{ color: "white" }}>
              <Link to="/" className="nav-text-link rounded">
                Home
              </Link>
            </Nav.Link>
            <Nav.Link href="/" style={{ color: "white" }}>
              <Link to="/snakes" className="nav-text-link rounded">
                About Snakes
              </Link>
            </Nav.Link>
            <Nav.Link href="/" style={{ color: "white" }}>
              <Link to="/news" className="nav-text-link rounded">
                Our News
              </Link>
            </Nav.Link>
            <Nav.Link href="/" style={{ color: "white" }}>
              <Link to="/publications" className="nav-text-link rounded">
                Resources and Publications
              </Link>
            </Nav.Link>
            <Nav.Link href="/" style={{ color: "white" }}>
              <Link to="/contact" className="nav-text-link rounded">
                About Us
              </Link>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default NavbarComponent;
