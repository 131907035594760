import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { CSVLink } from "react-csv";
import { Link } from 'react-router-dom/cjs/react-router-dom';
import ModalPopupScreen from '../../ModalScreen/ModalPopupScreen';
import { auth_token, authority_msg, main_web_url, put_shc_apps_data_url } from '../../WebController/WebController';
import './DashAppsDetails.css';


const DashAppsData = () => {
    const [appsDataList, setAppsDataList] = useState([]);
    const [id, setId] = useState(0);
    const [imagePath, setImagePath] = useState("");
    const [authToken, setAuthToken] = useState("");

    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    useEffect(() => {
        const auth_token_value = sessionStorage.getItem('auth_token');
        setAuthToken(auth_token_value);
        fetchSnakeList(auth_token_value);
    }, []);

    const fetchSnakeList = (auth_token_value) => {
        const formData = new FormData();
        formData.append("state", "1");
        formData.append(auth_token, auth_token_value);

        fetch(put_shc_apps_data_url, {
            method: "POST",
            body: formData,
        })
            .then((res) => res.json())
            .then((data) => {
                if (!data.error) {
                    setAppsDataList(data.data);
                }

            })
            .catch((error) => {
                console.log(authority_msg);
            });
    }

    const DeleteSnakeItem = (id, image_path) => {
        openModal();
        setId(id);
        setImagePath(image_path);
    }
    const popupCloseSubmit = () => {
        setIsModalOpen(false);
        const formData = new FormData();
        formData.append(auth_token, authToken);
        formData.append("state", "2");
        formData.append("id", id);
        formData.append("image_path", imagePath);

        fetch(put_shc_apps_data_url, {
            method: "POST",
            body: formData,
        })
            .then((res) => res.json())
            .then((data) => {
                fetchSnakeList(authToken);
            })
            .catch((error) => {
                console.log(authority_msg);
            });
    }
    return (
        <div className="container-fluid ">
            <div>
                <div className='row align-items-center '>
                    <div className='col-md-11 col-sm-9 text-center align-self-center'><p className=' title-p'>Apps Upload Data</p></div>
                    <CSVLink className='col-sm-3 col-md-1 csv-icon  p-3' data={appsDataList} target='_blank' filename={"apps_upload_data_list.csv"}> <FontAwesomeIcon icon={faDownload} /></CSVLink>;


                </div>
                <div className="row  justify-content-center ">

                    {appsDataList.map((item, index) => (
                        <div key={index} className=" col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 pb-2 mb-5 ">
                            <div
                                className="main-card  "

                            >
                                <img className=" img-fluid p-1 rounded d-block " alt="camera_or_gallery_image" src={main_web_url + item.image} />

                                <div className="p-3 mb-2  item-distribute">
                                    <h3
                                        className="text-center text-success"
                                        style={{ fontSize: "22px" }}
                                    >
                                        {item.question_1}
                                    </h3>
                                    <p className="text-start details_text ">
                                        {item.question_2}
                                    </p>
                                    <p className="text-start details_text ">
                                        {item.place}
                                    </p>
                                </div>
                                <div class="d-flex justify-content-between">
                                    <div onClick={() => DeleteSnakeItem(item.id, item.image)} class="p-2  col-md-6 text-center item-show item-del">Delete</div>
                                    <div class="p-2  col-md-6 text-center item-show item-read"><Link className=" item-link" to={"/apps_details/" + item.id} > Read </Link></div>
                                </div>


                            </div>
                        </div>
                    ))}

                    <ModalPopupScreen
                        isOpen={isModalOpen}
                        onClose={closeModal}
                        title="Delete Data"
                        msg="Are you sure, You want to delete this item?"
                        popupCloseSubmit={popupCloseSubmit} />
                </div>
            </div>
        </div>
    );
};

export default DashAppsData;