import { signInWithEmailAndPassword } from "firebase/auth";
import { doc, onSnapshot } from 'firebase/firestore';
import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import db, { auth } from "../../../firebase";
import ModalPopupScreen from '../../ModalScreen/ModalPopupScreen';
import './DashAuthScreen.css';

const DashAuthScreen = () => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const onFormSubmit = e => {
    e.preventDefault();

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        const uid = user.uid;
        onSnapshot(doc(db, "auth_user", uid), (snapshot) => {
          sessionStorage.setItem("auth_token", snapshot.data().auth_token);
          window.location.reload();
        })

      })
      .catch((error) => {
        openModal();
      });



  };

  const popupCloseSubmit = () => {
    setIsModalOpen(false);

  }

  return (
    <div
      className="d-flex justify-content-center align-items-center main-div"
    >
      <div className='sign-div'>
        <h1 className="text-center">Sign in</h1>
        <Form onSubmit={onFormSubmit}>
          <Form.Group>
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              onChange={e => {
                setEmail(e.target.value);
              }}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              onChange={e => {
                setPassword(e.target.value);
              }}
            />
          </Form.Group>
          <Button
            variant="primary"
            type="submit"
            className="w-100 mt-3"
          >
            Sign in
          </Button>
          <ModalPopupScreen
            isOpen={isModalOpen}
            onClose={closeModal}
            title="Error Authentication"
            msg="You are not eligible to experience the Auth View!"
            popupCloseSubmit={popupCloseSubmit} />
        </Form>
      </div>
    </div>
  );
};

export default DashAuthScreen;