export const auth_token_code = "5X0sR2BgMeHWn8GySfChwt1am5fEHN6WFYMNYFrETX36X8WVhypG+CeG9Vj5vLJzo0/ISdaUV3t0enR5hfkPzwwSR+X/gDR5dt/Otsz+";
export const auth_token = "auth_token";
export const snake_details_id_name = "snake_details_id";
export const authority_msg = "Authorization Failed";
export const main_web_url = "https://shc.cu.ac.bd/files/";
export const get_snake_details_url =  main_web_url+"web_php/get_snake_details.php";
export const get_publications_url =  main_web_url+"web_php/get_publications.php";
export const get_about_us_url =  main_web_url+"web_php/get_about_us.php";
export const put_snake_details_url =  main_web_url+"web_php/put_snake_details.php";
export const put_about_us_url =  main_web_url+"web_php/put_about_us.php";
export const put_publications_url =  main_web_url+"web_php/put_publication.php";
export const put_ref_url =  main_web_url+"web_php/put_ref.php";
export const put_shc_apps_data_url =  main_web_url+"web_php/put_shc_apps_data.php";
export const put_volunteer_url =  main_web_url+"web_php/put_volunteer.php";
export const put_snake_apps_url =  main_web_url+"web_php/put_snake_apps.php";
export const put_news_url =  main_web_url+"web_php/put_news.php";
export const get_news_url =  main_web_url+"web_php/get_news.php";
export const is_user_valid_url =  main_web_url+"web_php/isUserValid.php";
