/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import Loader from 'react-loader-spinner';
import ModalPopupScreen from '../../ModalScreen/ModalPopupScreen';
import { auth_token, authority_msg, put_volunteer_url } from '../../WebController/WebController';

const DashInsertVolunteer = ({ state, handleLoad, vol_id }) => {
    const [loading, setLoading] = useState(false);
    const [stateId, setStateId] = useState(0);
    const [authToken, setAuthToken] = useState("");

    const [nameEng, setNameEng] = useState("");
    const [nameBan, setNameBan] = useState("");
    const [addressEng, setAddressEng] = useState("");
    const [addressBan, setAddressBan] = useState("");
    const [phone, setPhone] = useState("");

    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    useEffect(() => {
        const auth_token_value = sessionStorage.getItem('auth_token');
        setAuthToken(auth_token_value);
        if (state === "Update") {
            setStateId(3);
            fetchVolunteerListData(auth_token_value);
        } else {
            setStateId(1);
        }
    }, []);
    const fetchVolunteerListData = async (auth_token_value) => {
        const formData = new FormData();
        formData.append("state", "5");
        formData.append(auth_token, auth_token_value);
        formData.append("id", vol_id);

        await fetch(put_volunteer_url, {
            method: "POST",
            body: formData,
        })
            .then((res) => res.json())
            .then((data) => {
                if (!data.error) {
                    setNameEng(data.data.name_eng);
                    setNameBan(data.data.name_ban);
                    setAddressEng(data.data.address_eng);
                    setAddressBan(data.data.address_ban);
                    setPhone("0" + data.data.phone);
                }

            })
            .catch((error) => {
                console.log(authority_msg);
            });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        openModal();
    };
    const popupCloseSubmit = () => {
        setIsModalOpen(false);
        setLoading(true);
        const formData = new FormData();
        formData.append(auth_token, authToken);
        formData.append("state", stateId);
        formData.append("name_eng", nameEng);
        formData.append("name_ban", nameBan);
        formData.append("address_eng", addressEng);
        formData.append("address_ban", addressBan);
        formData.append("phone", phone);
        formData.append("id", vol_id);

        fetch(put_volunteer_url, {
            method: "POST",
            body: formData,
        })
            .then((res) => res.json())
            .then((data) => {
                handleLoad();
                setLoading(false);
            })
            .catch((error) => {
                console.log(authority_msg);
            });
    };
    return (
        <div className="insert-main-div">
            {!loading ? (
                <div className="form-div">
                    <div>
                        <p className="text-center insert-text">{state} Volunteer Data</p>
                    </div>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group>
                            <Form.Label className="label-text">Name(Eng)</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Volunteer Name(English)"
                                value={nameEng}
                                onChange={(e) => {
                                    setNameEng(e.target.value);
                                }}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label className="label-text">Name(Ban)</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Volunteer Name(Bangla)"
                                value={nameBan}
                                onChange={(e) => {
                                    setNameBan(e.target.value);
                                }}
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label className="label-text">Address(Eng)</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Volunteer Address(English)"
                                value={addressEng}
                                onChange={(e) => {
                                    setAddressEng(e.target.value);
                                }}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label className="label-text">Address(Ban)</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Volunteer Address(Bangla)"
                                value={addressBan}
                                onChange={(e) => {
                                    setAddressBan(e.target.value);
                                }}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label className="label-text">Phone Number</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter volunteer Phone Number (01XXXXXXXXX)"
                                value={phone}
                                onChange={(e) => {
                                    setPhone(e.target.value);
                                }}
                            />
                        </Form.Group>

                        <Button
                            variant="primary"
                            type="submit"
                            className="w-100 mt-3 bg-success"
                        >
                            Submit
                        </Button>
                        <ModalPopupScreen
                            isOpen={isModalOpen}
                            onClose={closeModal}
                            title={state}
                            msg={"Are you sure, you want to " + state + " the data?"}
                            popupCloseSubmit={popupCloseSubmit} />
                    </Form>
                </div>
            ) : (
                <div className="row justify-content-center align-items-center loader-div">
                    <Loader type="Puff" color="green" height={100} width={100} />
                </div>
            )}
        </div>
    );
};


export default DashInsertVolunteer;