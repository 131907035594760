import React from "react";
import AllCardView from "../AllCardView/AllCardView";

const SnakeGroup = () => {
  return (
    <div className="container-fluid col-md-12 col-12 col-sm-12 col-lg-12 col-xl-10">
      <AllCardView />
    </div>
  );
};

export default SnakeGroup;
