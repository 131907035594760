import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { CSVLink } from "react-csv";
import Loader from 'react-loader-spinner';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import ModalPopupScreen from '../../ModalScreen/ModalPopupScreen';
import { auth_token, authority_msg, main_web_url, put_snake_details_url } from '../../WebController/WebController';
import DashInsertSnakeScreen from '../DashInsertSnakeScreen/DashInsertSnakeScreen';
import './DashSnakeScreen.css';

const DashSnakeScreen = () => {
    const [snakeDetails, setSnakeDetails] = useState([]);
    const [insert, setInsert] = useState(false);
    const [update, setUpdate] = useState(false);
    const [state, setState] = useState("");

    const [id, setId] = useState(0);
    const [imagePath, setImagePath] = useState("");
    const [authToken, setAuthToken] = useState("");

    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    useEffect(() => {
        const auth_token_value = sessionStorage.getItem('auth_token');
        setAuthToken(auth_token_value);
        fetchSnakeList(auth_token_value);
    }, []);

    const fetchSnakeList = (auth_token_value) => {
        const formData = new FormData();
        formData.append("state", "2");
        formData.append(auth_token, auth_token_value);

        fetch(put_snake_details_url, {
            method: "POST",
            body: formData,
        })
            .then((res) => res.json())
            .then((data) => {
                if (!data.error) {
                    setSnakeDetails(data.data);
                }


            })
            .catch((error) => {
                console.log(authority_msg);
            });
    }
    const handleLoad = () => {
        setInsert(false);
        setUpdate(false);
        fetchSnakeList(authToken);
    }

    const handleInsert = () => {
        setState("Insert");
        setInsert(true);
    }


    const handleUpdate = (id) => {
        setState("Update");
        setId(id);
        setUpdate(true);
    }

    const DeleteSnakeItem = (id, image_path) => {
        openModal();
        setId(id);
        setImagePath(image_path);
    }
    const popupCloseSubmit = () => {
        const auth_token_value = sessionStorage.getItem('auth_token');
        setIsModalOpen(false);
        const formData = new FormData();
        formData.append(auth_token, auth_token_value);
        formData.append("state", "3");
        formData.append("id", id);
        formData.append("image_path", imagePath);

        fetch(put_snake_details_url, {
            method: "POST",
            body: formData,
        })
            .then((res) => res.json())
            .then((data) => {
                fetchSnakeList(authToken);
            })
            .catch((error) => {
                console.log(authority_msg);
            });
    }
    const mainDiv = () => {
        return (<div>
            <div className=' btn-div'>
                <button className='buttonStyle mt-2 ' onClick={() => { handleInsert() }}>
                    + Add
                </button>
                <CSVLink className='csv-icon  p-3' data={snakeDetails} target='_blank' filename={"all_snake_data_list.csv"}> <FontAwesomeIcon icon={faDownload} /></CSVLink>;
            </div>

            <div className=" ">
                {(snakeDetails.length > 0) ? (
                    <div className="row  justify-content-center ">
                        {snakeDetails.map((item) => (
                            <div className=" col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 pb-2 mb-5 ">
                                <div
                                    className="main-card  "

                                >
                                    <img className=" img-fluid p-1" alt={item.english_name} src={main_web_url + item.image} />

                                    <div className="p-3 mb-2  item-distribute">
                                        <h3
                                            className="text-center text-success"
                                            style={{ fontSize: "22px" }}
                                        >
                                            {item.english_name}
                                        </h3>
                                        <p className="text-start details_text ">
                                            {item.distributions}
                                        </p>
                                    </div>
                                    <div class="d-flex justify-content-between">
                                        <div onClick={() => DeleteSnakeItem(item.id, item.image)} class="p-2  col-md-4 text-center item-show item-del">Delete</div>
                                        <div onClick={() => handleUpdate(item.id)} class="p-2 col-md-4 text-center item-show item-edit">Edit</div>
                                        <div class="p-2  col-md-4 text-center item-show item-read"><Link className=" item-link" to={"/snake_details/" + item.id} > Read </Link></div>
                                    </div>


                                </div>
                            </div>
                        ))}

                        <ModalPopupScreen
                            isOpen={isModalOpen}
                            onClose={closeModal}
                            title="Delete Snake Item"
                            msg="Are you sure, You want to delete this item?"
                            popupCloseSubmit={popupCloseSubmit} />
                    </div>
                ) : (
                    <div className="row justify-content-center align-items-center loader_div">
                        <Loader
                            type="Puff"
                            color="green"
                            height={100}
                            width={100}
                            timeout={5000} //5 secs
                        />
                    </div>
                )}
            </div>
        </div>);
    }
    return (
        <div className="container-fluid ">
            <div>
                {
                    insert || update ? <DashInsertSnakeScreen
                        state={state}
                        handleLoad={handleLoad}
                        id={id} /> :
                        mainDiv()
                }
            </div>
        </div>
    );
};

export default DashSnakeScreen;